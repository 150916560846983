import { useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { useHistory, useRouteMatch } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import UIkit from 'uikit'

import Button from 'components/Button'
import CalendarDropdownMenu, {
  useCalendarDropDown
} from 'components/CalendarDropdownMenu'
import { SVGIcon } from 'components/Icon'
import { useRefetch } from 'components/RefetchProvider'
import AddToCalendar from 'components/cards/EventCard/AddToCalendar'
import { ChevronDownThin } from 'components/icons'
import AddEventCalendarIcon from 'components/icons/AddEventCalendarIcon'
import PlayCircleBorderIcon from 'components/icons/PlayCircleBorderIcon'

import {
  EventPartsFragment,
  useCancelRsvpMutation,
  useNotAttendingMutation,
  useRsvpMutation
} from 'gql'

import { getCurrentTimezone } from 'utils/date'
import notifyError from 'utils/errorNotifier'
import { isEventInSession } from 'utils/eventUtils'
import { trackCtaClicked } from 'utils/tracking/analytics'
import sendEventTrackingEvent from 'utils/tracking/event'
import { COHORT_EVENT_LINK_URL, EVENT_LINK_URL, makeUrl } from 'utils/url'

import PopupMenu from './PopupMenu'

const ctaClickedLocation = (path: string) => {
  if (path === '/') {
    return 'homepage event card'
  } else if (path === '/events') {
    return 'events_index event card'
  } else if (path.match(/\/events\/:id-:slug*/)) {
    return 'event_page event card'
  } else if (path.match(/\/directory\/:userId*/)) {
    return 'member_profile event card'
  } else if (path.match(/\/programs*/)) {
    return 'program_page event card'
  } else {
    return 'event card'
  }
}

interface JoinCtaProps {
  event: EventPartsFragment
  attendingStatus?: string
  eventAttending: boolean
  eventNoRsvp: boolean
  timezone: string | undefined
  eventIsInSession: boolean
  rsvpYes: any
  dataTestId: string
  setIsAddToCalendarOpen: (addToCalendarFlag: boolean) => void
  buttonSize: string
  disable: boolean
}

const JoinCTA = ({
  event,
  eventAttending,
  eventNoRsvp,
  eventIsInSession,
  rsvpYes,
  timezone,
  dataTestId,
  setIsAddToCalendarOpen,
  buttonSize,
  disable
}: JoinCtaProps) => {
  const history = useHistory()
  const size = getSizeClasses(buttonSize)
  const { path } = useRouteMatch()
  const ctaLocation = useMemo(() => ctaClickedLocation(path), [path])

  const handleClickDuringEvent = async (e: any) => {
    e.stopPropagation()
    sendEventTrackingEvent('join_now_click', event, timezone)
    if (!eventAttending) {
      await rsvpYes()
    }

    if (event.kind === 'Cohort Event') {
      const cohortEvent = event.cohorts[0]
      history.push(
        makeUrl(COHORT_EVENT_LINK_URL, { slug: cohortEvent.slug, eventUUID: event.uuid })
      )
    } else {
      history.push(makeUrl(EVENT_LINK_URL, { eventUUID: event.uuid }))
    }
  }

  const onAttendClick = async (e: any) => {
    e.stopPropagation()
    await rsvpYes()
    setIsAddToCalendarOpen(true)

    trackCtaClicked({
      cta_type: 'button',
      cta_location: ctaLocation,
      related_identifiers: {
        id: event.id
      },
      text: 'attend'
    })
  }

  if (eventIsInSession) {
    return (
      <Button
        dataTest={dataTestId}
        className={`${size} min-w-[100px] gap-x-2 text-xs`}
        variant="outline"
        color="teal"
        shape="rounded-full"
        size="x-small"
        onClick={handleClickDuringEvent}
      >
        Join Now
      </Button>
    )
  }

  return (
    <>
      {!event.past && (eventNoRsvp || !eventAttending) && (
        <>
          <ReactTooltip
            className="w-64"
            backgroundColor="#4d4d4d"
            effect="solid"
            multiline={true}
          />
          <div
            data-tip={
              disable
                ? 'This is an event from a Live Program you are not enrolled in.'
                : ''
            }
          >
            <Button
              dataTest={dataTestId}
              className={`${size} min-w-[100px] gap-x-2 border-[1.5px] text-xs`}
              variant="fill"
              shape="rounded-full"
              size="x-small"
              iconClassName="!mr-1"
              onClick={onAttendClick}
              iconBefore={<AddEventCalendarIcon />}
              disabled={disable}
            >
              Attend
            </Button>
          </div>
        </>
      )}

      {!event.past && eventAttending && (
        <Button
          dataTest={dataTestId}
          className={`${size} min-w-[100px] gap-x-2 border-[1.5px] text-xs`}
          variant="outline"
          shape="rounded-full"
          size="x-small"
          iconClassName="!ml-1 !w-3"
          onClick={(e) => e.stopPropagation()}
          iconAfter={<ChevronDownThin className="fill-current" />}
        >
          Going
        </Button>
      )}
    </>
  )
}

function getSizeClasses(size: string) {
  if (size === 'small') return 'px-2.5 py-1.5'
  if (size === 'large') return 'py-[10px] px-7'
}

interface AttendButtonBaseProps {
  event: EventPartsFragment
  timezone?: string | null
  attendingStatus?: string | null
  dataTestId: string
  dropdownPosition?: string
  arrowPosition?: string
  buttonSize?: string
  disable?: boolean
}

interface AttendCallbackArg extends EventPartsFragment {
  attendingStatus: string
}

interface AttendButtonProps extends AttendButtonBaseProps {
  rsvpConfirmHandler: () => void
  rsvpCancelHandler: (e: any) => void
}

export interface AttendButtonContainerProps extends AttendButtonBaseProps {
  attendCallback?: (a: AttendCallbackArg) => void
  notAttendCallback?: (a: AttendCallbackArg) => void
}

export const AttendButton = ({
  event,
  timezone,
  attendingStatus,
  dataTestId,
  rsvpConfirmHandler,
  rsvpCancelHandler,
  dropdownPosition = 'top-center',
  arrowPosition = 'bottom',
  buttonSize = 'small',
  disable = false
}: AttendButtonProps) => {
  const eventNoRsvp = !attendingStatus
  const eventAttending = attendingStatus === 'Attending'
  const eventRecordingAvailable = event.past && !!event.summaryWistiaCodeOptional
  const currentTimezone = getCurrentTimezone(timezone)
  const eventIsInSession = isEventInSession(event.startsAtUtc, event.endsAtUtc)
  const hasPendingRecording =
    event.past && event.willBeRecorded && !event.summaryWistiaCodeOptional
  const handleViewEventDetailsClick = () => {
    // track the click, but don't update the URL because the EventCard container is a link itself
    // and we don't want to cause two event fires
    sendEventTrackingEvent('view_event_details_click', event, currentTimezone)
  }
  const { isAddToCalendarOpen, setIsAddToCalendarOpen } = useCalendarDropDown()

  const addToCalendarHandler = (e: any) => {
    e.stopPropagation()
    setIsAddToCalendarOpen(true)
  }

  // We're utilizing this container to create a portal from the event card for the
  // calendar dropdown. We only want to create a portal when this container is present.
  const attendButtonContainer = document.getElementById(
    `calendar-dropdown-portal-${event.id}`
  )

  return (
    <>
      <JoinCTA
        dataTestId={dataTestId}
        event={event}
        eventAttending={eventAttending}
        eventNoRsvp={eventNoRsvp}
        timezone={currentTimezone}
        eventIsInSession={eventIsInSession}
        rsvpYes={rsvpConfirmHandler}
        setIsAddToCalendarOpen={setIsAddToCalendarOpen}
        buttonSize={buttonSize}
        disable={disable}
      />
      {hasPendingRecording && (
        <>
          <ReactTooltip
            effect="solid"
            backgroundColor="#4D4D4D"
            multiline={true}
            id={`pending-recording-${event.id}`}
          >
            A recording of this session will be <br /> added within 72 hours of the event
          </ReactTooltip>
          <span
            data-for={`pending-recording-${event.id}`}
            data-tip="View all live program participants"
          >
            <SVGIcon name="three-dots" width="20" height="20" />
          </span>
        </>
      )}

      {eventRecordingAvailable && (
        <Button
          dataTest={dataTestId}
          className="min-w-[100px] gap-x-2 border-[1.5px] px-2.5 py-1.5 text-xs"
          variant="outline"
          shape="rounded-full"
          size="x-small"
          iconClassName="!mr-1"
          iconBefore={<PlayCircleBorderIcon />}
          onClick={handleViewEventDetailsClick}
        >
          Replay
        </Button>
      )}
      {!eventIsInSession && !event.past && eventAttending && !isAddToCalendarOpen && (
        <PopupMenu
          id={`dropdown-${event.id}`}
          ukDropdown={`mode: click; pos: ${dropdownPosition}; offset: 25; flip: false;`}
          arrowPosition={arrowPosition}
        >
          <PopupMenu.Item onClick={rsvpCancelHandler}>Can&apos;t make it</PopupMenu.Item>

          <PopupMenu.Item onClick={addToCalendarHandler}>Add to Calendar</PopupMenu.Item>
        </PopupMenu>
      )}

      {isAddToCalendarOpen &&
        attendButtonContainer &&
        createPortal(
          <CalendarDropdownMenu
            containerClassName="absolute bg-white z-[100]"
            onClose={() => {
              setIsAddToCalendarOpen(false)
            }}
          >
            <AddToCalendar event={event} timezone={timezone} />
          </CalendarDropdownMenu>,
          attendButtonContainer
        )}

      {isAddToCalendarOpen && !attendButtonContainer && (
        <CalendarDropdownMenu
          containerClassName="absolute bg-white z-[100]"
          offset={window.innerWidth < 600 ? 'right-0 bottom-0' : 'right-4'}
          onClose={() => {
            setIsAddToCalendarOpen(false)
          }}
        >
          <AddToCalendar event={event} timezone={timezone} />
        </CalendarDropdownMenu>
      )}
    </>
  )
}

const AttendButtonContainer = ({
  event,
  attendCallback,
  notAttendCallback,
  timezone,
  dataTestId,
  ...rest
}: AttendButtonContainerProps) => {
  const [createRsvp] = useRsvpMutation()
  const [cancelRsvp] = useCancelRsvpMutation()
  const [notAttending] = useNotAttendingMutation()
  const { setFetchChecklistTasks } = useRefetch()

  const [attendingStatus, setAttendingStatus] = useState(event.attendingStatus)

  const currentTimezone = getCurrentTimezone(timezone)

  const eventIsInSession = isEventInSession(event.startsAtUtc, event.endsAtUtc)

  const rsvpYesOrAttend = async () => {
    sendEventTrackingEvent('attend_click', event, currentTimezone)

    if (!eventIsInSession) {
      if (document.getElementById(`#event-card-dropdown-${event.id}`)) {
        window.UIkit.dropdown(`#event-card-dropdown-${event.id}`).hide(0)
      }
    }

    let data
    try {
      const response = await createRsvp({
        variables: {
          input: {
            id: event.id.toString(),
            timezone: currentTimezone,
            happening: eventIsInSession
          }
        }
      })
      data = response.data
      setFetchChecklistTasks(true)
    } catch (err) {
      return notifyError(err)
    }

    if (data?.rsvp?.errors?.length) {
      const { errors } = data.rsvp
      UIkit.notification({
        message: `<span uk-icon="icon: warning"></span> ${errors[0]}`,
        status: 'warning',
        pos: 'top-right'
      })

      return notifyError(errors)
    }

    setAttendingStatus('Attending')
    if (attendCallback) {
      attendCallback({ ...event, attendingStatus: 'Attending' })
    }
  }

  const rsvpNotAttending = async (e: any) => {
    e.stopPropagation()

    sendEventTrackingEvent('cant_make_it_click', event, currentTimezone)

    if (document.getElementById(`#event-card-dropdown-${event.id}`)) {
      window.UIkit.dropdown(`#event-card-dropdown-${event.id}`).hide(0)
    }

    let data
    try {
      const response = await notAttending({
        variables: { input: { id: event.id.toString() } }
      })
      data = response.data
    } catch (err) {
      return notifyError(err)
    }

    if (data?.notAttending?.errors?.length) {
      const { errors } = data.notAttending
      UIkit.notification({
        message: `<span uk-icon="icon: warning"></span> ${errors[0]}`,
        status: 'warning',
        pos: 'top-right'
      })

      return notifyError(errors)
    }

    setAttendingStatus('Not Attending')

    if (notAttendCallback) {
      notAttendCallback({ ...event, attendingStatus: 'Not Attending' })
    }
  }

  const rsvpCancel = async (e: any) => {
    e.stopPropagation()

    sendEventTrackingEvent('cant_make_it_click', event, currentTimezone)

    if (document.getElementById(`#event-card-dropdown-${event.id}`)) {
      window.UIkit.dropdown(`#event-card-dropdown-${event.id}`).hide(0)
    }

    let data
    try {
      const response = await cancelRsvp({
        variables: { input: { id: event.id.toString() } }
      })
      data = response.data
    } catch (err) {
      return notifyError(err)
    }

    if (data?.cancelRsvp?.errors?.length) {
      const { errors } = data.cancelRsvp
      UIkit.notification({
        message: `<span uk-icon="icon: warning"></span> ${errors[0]}`,
        status: 'warning',
        pos: 'top-right'
      })

      return notifyError(errors)
    }

    setAttendingStatus('Not Attending')

    if (notAttendCallback) {
      notAttendCallback({ ...event, attendingStatus: 'Not Attending' })
    }
  }

  return (
    <AttendButton
      event={event}
      attendingStatus={attendingStatus}
      rsvpConfirmHandler={rsvpYesOrAttend}
      dataTestId={dataTestId}
      rsvpCancelHandler={(e: any) =>
        attendingStatus === 'attending' ? rsvpCancel(e) : rsvpNotAttending(e)
      }
      {...rest}
    />
  )
}

export default AttendButtonContainer
